import Vue from 'vue';
import Vuetify from 'vuetify/lib';
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        //primary: colors.green,
        primary: '#0069b4',
        secondary: '#3ca4dc',
        accent: '#FF9800',
        error: '#b71c1c',
      },
      dark: {
        //primary: colors.green.darken2,
        primary: "#0069b4",
        secondary: '#3ca4dc',
        accent: '#FF5722',
        error: '#b71c1c',
      },
    }
  },
});

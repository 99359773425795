export default class User {
  constructor(username, email, phone, company, note, password) {
    this.username = username;
    this.email = email;
    this.phone = phone;
    this.company = company;
    this.note = note;
    this.password = password;
  }
}

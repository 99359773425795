
export const table = {
  namespaced: true,
  state: { useTable: false},
  
  actions: {
    switch({ commit }) {
      commit('changeTable');
    },
  },
  
  mutations: {
    changeTable(state) {
      state.useTable = !state.useTable;
      //console.log("Vuex state: " + state.useTable)
    },
  }
};
